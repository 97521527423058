import { Component, Input, OnInit } from '@angular/core';
import {
  MesoCycle,
  TemplateTrainingDay,
  TemplateTrainingSlot,
  TrainingLog,
} from 'src/app/core/thecoach';

@Component({
  selector: 'app-training-log-slot-detail-view',
  template: ` <div
    class="flex flex-row w-full justify-center rounded-md bg-gradient-to-r"
    [ngClass]="{
      'from-red-200': gradientWeight === -1,
      'from-yellow-200': gradientWeight === 0,
      'from-green-200': gradientWeight === 1,

      'via-red-200': gradientVolume === -1,
      'via-yellow-200': gradientVolume === 0,
      'via-green-200': gradientVolume === 1,

      'to-red-200': gradientReps === -1,
      'to-yellow-200': gradientReps === 0,
      'to-green-200': gradientReps === 1,
    }"
  >
    <div
      [innerHTML]="displaySlotDetail(slot)"
      class="flex flex-col whitespace-pre-line"
    ></div>
    <div class="group flex relative items-center pl-0.5" *ngIf="slot.comment">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke-width="1.5"
        stroke="currentColor"
        class="w-4 h-4 stroke-yellow-600"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z"
        />
      </svg>
      <span
        class="pointer-events-none z-20 whitespace-nowrap group-hover:opacity-100 w-50 transition-opacity bg-gray-800 px-1 text-sm text-gray-100 rounded-md absolute left-1/2
    -translate-x-1/2 translate-y-2 opacity-0 m-4 mx-auto"
        >{{ slot.comment }}</span
      >
    </div>
  </div>`,
  styles: [],
})
export class TrainingLogSlotDetailViewComponent implements OnInit {
  ngOnInit(): void {
    this.displayCalculatedAdaptations(this.slot, this.trainingday, this.i);
  }
  @Input() slot!: TemplateTrainingSlot;
  @Input() trainingday!: TemplateTrainingDay;
  @Input() i!: number;
  @Input() selectedMesoCycle!: MesoCycle | undefined | null;
  @Input() selectedTrainingLog!: TrainingLog | undefined | null;

  gradientWeight: number | undefined;
  gradientVolume: number | undefined;
  gradientReps: number | undefined;

  displaySlotDetail(slot: TemplateTrainingSlot): string {
    let returnString = '';
    let weightSame = slot.inputSets?.every((iS, index, array) => {
      if (iS.hasOwnProperty('weight')) {
        // Compare the 'weight' property of the current element with the first element
        return iS.weight === array[0].weight;
      } else {
        // If 'weight' property is missing in any element, return false
        return false;
      }
    });

    if (
      weightSame &&
      slot.inputSets &&
      slot.inputSets.length > 0 &&
      slot.inputSets?.[0].weight !== undefined
    ) {
      returnString = slot.inputSets![0].weight?.toString() + 'kg x ';
      let repString = '';
      slot.inputSets?.forEach((inputSet) => {
        if (!inputSet.reps) {
          repString = '-  ';
        } else {
          repString = repString + inputSet.reps + ', ';
        }
      });

      return (returnString + repString).slice(0, -2);
    }

    let tempString = '';
    slot.inputSets?.forEach((inputSet) => {
      if (
        inputSet.weight?.toString() !== undefined &&
        inputSet.weight !== undefined &&
        inputSet.weight?.toString() !== ''
      ) {
        if (isNaN(inputSet.weight)) {
          tempString = '- ';
        } else {
          tempString = inputSet.weight?.toString() as string;
        }

        if (inputSet.reps !== undefined && inputSet.reps?.toString() !== '') {
          returnString =
            returnString + tempString + 'kg x ' + inputSet.reps + ' \n';
        }
      }
    });

    if (returnString === '') {
      return '-';
    }

    return returnString.slice(0, -2);
  }

  displayCalculatedAdaptations(
    slot: TemplateTrainingSlot,
    td: TemplateTrainingDay,
    pos: number,
  ) {
    let index = undefined;
    let sessionTrainingDays: TemplateTrainingDay[] = [];
    let sessionslots: TemplateTrainingSlot[] = [];

    if (this.selectedMesoCycle && this.selectedMesoCycle.mesoname) {
      index = this.selectedTrainingLog?.mesoCycle?.findIndex(
        (mesoCycle) => mesoCycle.mesoname === this.selectedMesoCycle?.mesoname,
      );
    }

    if (index || index === 0) {
      this.selectedTrainingLog?.mesoCycle
        ?.at(index)
        ?.trainingDays?.forEach((trainingDay) => {
          if (trainingDay.trainingDayName === td.trainingDayName)
            sessionTrainingDays.push(trainingDay);
        });
      sessionTrainingDays = sessionTrainingDays.sort(
        (a, b) => b.startDate!.getTime() - a.startDate!.getTime(),
      );

      sessionTrainingDays.forEach((session) => {
        session.trainingSlots?.forEach((sessionslot) => {
          if (
            slot.exerciseId &&
            sessionslot.exerciseId === slot.exerciseId &&
            sessionslot.reps === slot.reps

            //sessionslot.slot == slot.slot
          ) {
            sessionslots.push(sessionslot);
          } else if (
            sessionslot.exerciseId === slot.exerciseId &&
            sessionslot.slot === slot.slot
          ) {
            sessionslots.push(sessionslot);
          }
        });
      });
    }

    if (pos === sessionslots.length - 2) {
      this.gradientWeight = this.compareWeightsInSlots(
        slot,
        sessionslots[pos + 1],
      );

      this.gradientVolume = this.compareTrainingSlotsVolume(
        slot,
        sessionslots[pos + 1],
      );

      this.gradientReps = this.compareRepsInSlots(slot, sessionslots[pos + 1]);
    } else {
      if (sessionslots[pos + 1] !== undefined) {
        this.gradientWeight = this.compareWeightsInSlots(
          slot,
          sessionslots[pos + 1],
        );

        this.gradientVolume = this.compareTrainingSlotsVolume(
          slot,
          sessionslots[pos + 1],
        );

        this.gradientReps = this.compareRepsInSlots(
          slot,
          sessionslots[pos + 1],
        );
      }
    }
  }

  compareWeightsInSlots(
    slotA: TemplateTrainingSlot,
    slotB: TemplateTrainingSlot,
  ) {
    let sumA = 0;
    slotA.inputSets?.forEach((iS) => {
      sumA += Number(iS.weight);
    });
    let sumB = 0;
    slotB.inputSets?.forEach((iS) => {
      sumB += Number(iS.weight);
    });

    if (sumA === sumB) {
      return 0;
    } else if (sumA < sumB) {
      return -1;
    } else if (sumA > sumB) {
      return 1;
    } else return 0;
  }

  compareRepsInSlots(slotA: TemplateTrainingSlot, slotB: TemplateTrainingSlot) {
    let sumA = 0;
    slotA.inputSets?.forEach((iS) => {
      sumA += Number(iS.reps);
    });
    let sumB = 0;
    slotB.inputSets?.forEach((iS) => {
      sumB += Number(iS.reps);
    });

    if (sumA === sumB) {
      return 0;
    } else if (sumA < sumB) {
      return -1;
    } else if (sumA > sumB) {
      return 1;
    } else return 0;
  }

  checkSlotTotalVolume(
    slot: TemplateTrainingSlot,
    td: TemplateTrainingDay,
    pos: number,
  ): number {
    let index = undefined;
    let sessionTrainingDays: TemplateTrainingDay[] = [];
    let sessionslots: TemplateTrainingSlot[] = [];

    if (this.selectedMesoCycle && this.selectedMesoCycle.mesoname) {
      index = this.selectedTrainingLog?.mesoCycle?.findIndex(
        (mesoCycle) => mesoCycle.mesoname === this.selectedMesoCycle?.mesoname,
      );
    }

    if (index === -1) return -1;
    if (index || index === 0) {
      this.selectedTrainingLog?.mesoCycle
        ?.at(index)
        ?.trainingDays?.forEach((trainingDay) => {
          if (trainingDay.trainingDayName === td.trainingDayName)
            sessionTrainingDays.push(trainingDay);
        });

      sessionTrainingDays = sessionTrainingDays.sort(
        (a, b) => b.startDate!.getTime() - a.startDate!.getTime(),
      );

      sessionTrainingDays.forEach((session) => {
        session.trainingSlots?.forEach((sessionslot) => {
          if (
            sessionslot.exerciseId === slot.exerciseId
            //&& sessionslot.slot == slot.slot
          )
            // sessionslot.date = convertDateObject(sessionslot.date as Date);
            sessionslots.push(sessionslot);
        });
      });
    }

    if (pos === sessionslots.length - 2) {
      return this.compareTrainingSlotsVolume(slot, sessionslots[pos + 1]);
    } else {
      if (sessionslots[pos + 1] !== undefined)
        return this.compareTrainingSlotsVolume(slot, sessionslots[pos + 1]);

      return 2;
    }
  }

  compareTrainingSlotsVolume(
    slotA: TemplateTrainingSlot,
    slotB: TemplateTrainingSlot,
  ) {
    let volumeA: number = 0;
    let volumeB: number = 0;

    if (slotA.inputSets)
      slotA.inputSets?.forEach((inputSet) => {
        if (inputSet.reps && inputSet.weight)
          volumeA += inputSet.reps * inputSet.weight;
      });

    if (slotB.inputSets)
      slotB.inputSets?.forEach((inputSet) => {
        if (inputSet.reps && inputSet.weight)
          volumeB += inputSet.reps * inputSet.weight;
      });

    if (volumeA > volumeB) {
      return 1;
    } else if (volumeA === volumeB) {
      return 0;
    } else {
      return -1;
    }
  }
}
