import {
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { Subscription } from 'rxjs';
import {
  TrainingLog,
  AppUser,
  MesoCycle,
  getSessionsForLoggedTrainingDay,
  TemplateTrainingDay,
  durationFromSessionMaster,
  TemplateTrainingSlot,
  TrainingPlan,
} from 'src/app/core/thecoach';
import { LogService } from 'src/app/services/log.service';
import { TrainingplanService } from 'src/app/services/trainingplan.service';

@Component({
  selector: 'app-training-table-dashboard',
  template: `
    <div
      class="overflow-x-auto shadow-md rounded-lg bg-white  w-full h-fit ring-1 ring-gray-300"
      *ngIf="loadedTrainingLog !== null; else loading"
    >
      <div class="px-4 py-5 sm:p-6">
        <div
          *ngFor="
            let trainingday of this.loadedTrainingPlanMesoCycle?.trainingDays
          "
          class=""
        >
          <h3 class="italic font-semibold text-gray-900 text-sm mt-2">
            Trainingday: {{ trainingday.trainingDayName }}
          </h3>
          <table class="divide-y divide-gray-300 w-fit table-fixed border-2  ">
            <thead>
              <tr class="divide-x divide-gray-200">
                <th
                  scope="col"
                  class="py-2 px-0.5 text-left text-sm font-semibold text-gray-900 w-[2rem]"
                >
                  Slot
                </th>
                <th
                  scope="col"
                  class="px-2 py-2 text-left text-sm font-semibold text-gray-900 w-[9rem]"
                >
                  Name
                </th>
                <th
                  scope="col"
                  class="px-2 py-2 text-left text-sm font-semibold text-gray-900 w-[2rem]"
                >
                  <div class=" group flex relative items-center justify-center">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      class="w-4 h-4"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z"
                      />
                    </svg>
                    <span
                      class="pointer-events-none z-20 whitespace-nowrap group-hover:opacity-100 w-50 transition-opacity bg-gray-800 px-1 text-sm text-gray-100 rounded-md absolute left-1/2
                                  -translate-x-1/2 translate-y-4 opacity-0 m-4 mx-auto"
                      >Exercise Description</span
                    >
                  </div>
                </th>
                <th
                  scope="col"
                  class="py-2 px-0.5 text-center text-sm font-semibold text-gray-900 w-[2.5rem]"
                >
                  Sets
                </th>
                <th
                  scope="col"
                  class="py-2 px-2 text-center text-sm font-semibold text-gray-900 w-[3.5rem]"
                >
                  Reps
                </th>
                <th
                  scope="col"
                  class="py-2 px-2 text-center text-sm font-semibold text-gray-900 w-[2.5rem]"
                >
                  RIR
                </th>
                <ng-container
                  *ngIf="
                    getSessionForLoggedTrainingDay(trainingday)!.length > 0
                  "
                >
                  <th
                    class="py-2 px-0.5 text-center text-sm font-semibold text-gray-900 whitespace-nowrap	w-[12rem] "
                    *ngFor="
                      let session of getSessionForLoggedTrainingDay(trainingday)
                    "
                  >
                    <p
                      *ngIf="
                        session.startDate && session.endDate;
                        else startdate
                      "
                    >
                      {{ session.startDate | date: 'dd-MM' }} -
                      {{ durationFromSession(session) }}
                    </p>

                    <ng-template #startdate>
                      {{ session.startDate | date: 'dd-MM HH:mm' }}
                    </ng-template>

                    <p
                      *ngIf="session.sessionRating"
                      class="italic text-xs font-normal text-gray-600"
                    >
                      Rating: {{ session.sessionRating }}/10
                    </p>
                  </th>
                </ng-container>
              </tr>
            </thead>
            <tbody class="divide-y divide-gray-200 bg-white">
              <tr
                class="divide-x divide-gray-200"
                *ngFor="
                  let trainingSlot of getUniqueTrainingSlots(trainingday);
                  let j = index
                "
              >
                <td
                  class="whitespace-normal py-2 px-2 text-sm font-medium text-gray-900 text-center truncate"
                >
                  {{ trainingSlot.slot }}
                </td>
                <td
                  class="whitespace-nowrap p-2 text-sm text-gray-500 truncate ..."
                >
                  {{ trainingSlot.exerciseName }}
                </td>
                <td
                  class="p-2 text-sm text-gray-500 flex flex-row  items-center justify-center"
                >
                  <div class=" group flex relative items-center">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      class="w-4 h-4"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z"
                      />
                    </svg>
                    <span
                      class="pointer-events-none z-20 whitespace-nowrap group-hover:opacity-100 w-50 transition-opacity bg-gray-800 px-1 text-sm text-gray-100 rounded-md absolute left-1/2
                                  -translate-x-1/2 translate-y-4 opacity-0 m-4 mx-auto"
                      >{{ trainingSlot.exerciseDescription }}</span
                    >
                  </div>
                </td>

                <td
                  class="whitespace-nowrap py-2  text-sm text-gray-500 text-center"
                >
                  {{ trainingSlot.sets }}
                </td>
                <td
                  class="whitespace-nowrap py-2 text-sm text-gray-500 text-center"
                >
                  {{ trainingSlot.reps }}
                </td>
                <td
                  class="whitespace-nowrap py-2  text-sm text-gray-500 text-center"
                >
                  {{ trainingSlot.rir }}
                </td>
                <ng-container>
                  <td
                    *ngFor="
                      let slot of getTrainingSlotSessionData(
                        trainingday,
                        trainingSlot
                      );
                      let i = index
                    "
                    class="whitespace-nowrap py-2 items-center px-1 text-sm text-gray-500 text-center"
                  >
                    <ng-container
                      *ngIf="
                        loadedTrainingPlanMesoCycle &&
                        loadedTrainingLogMesoCycle &&
                        selectedLog
                      "
                    >
                      <app-training-log-slot-detail-view
                        [slot]="slot"
                        [trainingday]="trainingday"
                        [i]="i"
                        [selectedMesoCycle]="this.loadedTrainingLogMesoCycle"
                        [selectedTrainingLog]="selectedLog"
                      ></app-training-log-slot-detail-view>
                    </ng-container>
                  </td>
                </ng-container>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>

    <ng-template #loading>
      <ngx-skeleton-loader count="5" appearance="line"></ngx-skeleton-loader>
    </ng-template>
  `,
  styles: [],
})
export class TrainingTableDashboardComponent implements OnInit, OnDestroy {
  @Input() selectedLog!: TrainingLog;
  @Input() client!: AppUser;
  @Input() loadedTrainingPlan!: TrainingPlan;
  mesoCycleSubscription: Subscription | undefined = undefined;
  loadedTrainingPlanMesoCycle: MesoCycle | null = null;
  loadedTrainingLogMesoCycle: MesoCycle | null = null;
  loadedTrainingLog: TrainingLog | null = null;
  constructor(private trainingLogService: LogService) {}
  ngOnInit(): void {
    if (this.loadedTrainingPlan && this.loadedTrainingPlan.mesoCycles)
      this.loadedTrainingPlanMesoCycle = this.loadedTrainingPlan.mesoCycles[0];

    this.loadedTrainingLog = this.selectedLog;

    this.getLatestMesoCycle(this.client);
  }

  ngOnDestroy(): void {
    this.mesoCycleSubscription?.unsubscribe();
  }

  getLatestMesoCycle(client: AppUser) {
    this.mesoCycleSubscription = this.trainingLogService
      .getLatestMesoCycleForUser(client.id!, this.selectedLog.id as string)
      .subscribe((mesoCycle) => {
        this.loadedTrainingLogMesoCycle = mesoCycle;

        this.loadedTrainingLogMesoCycle!.trainingDays =
          this.loadedTrainingLogMesoCycle?.trainingDays?.sort(
            (a, b) => b.startDate!.getTime() - a.startDate!.getTime(),
          );
        if (
          this.loadedTrainingLogMesoCycle &&
          this.loadedTrainingPlanMesoCycle &&
          this.loadedTrainingPlanMesoCycle.trainingDays
        ) {
          const sessionLimit =
            this.loadedTrainingPlanMesoCycle.trainingDays.length * 5;

          if (sessionLimit < 20) {
            this.loadedTrainingLogMesoCycle.trainingDays =
              this.loadedTrainingLogMesoCycle?.trainingDays?.slice(
                0,
                sessionLimit,
              );
          } else {
            this.loadedTrainingLogMesoCycle.trainingDays =
              this.loadedTrainingLogMesoCycle?.trainingDays?.slice(0, 20);
          }
        } else if (this.loadedTrainingLogMesoCycle) {
          this.loadedTrainingLogMesoCycle.trainingDays =
            this.loadedTrainingLogMesoCycle?.trainingDays?.slice(0, 20);
        }
      });
  }

  getSessionForLoggedTrainingDay(session: TemplateTrainingDay) {
    if (this.loadedTrainingLogMesoCycle)
      return getSessionsForLoggedTrainingDay(
        session,
        this.loadedTrainingLogMesoCycle as MesoCycle,
      );

    return [];
  }

  durationFromSession(session: TemplateTrainingDay) {
    return durationFromSessionMaster(session);
  }

  getUniqueTrainingSlots(td: TemplateTrainingDay): TemplateTrainingSlot[] {
    let uniqueSlots: TemplateTrainingSlot[] = [];

    const sessionsForTrainingDay = this.getSessionForLoggedTrainingDay(td);

    if (sessionsForTrainingDay && sessionsForTrainingDay.length > 0) {
      const index = sessionsForTrainingDay.reduce(
        (a: number, arr: TemplateTrainingDay, idx: number) =>
          arr.trainingSlots!.length >
          sessionsForTrainingDay.at(a)!.trainingSlots!.length
            ? idx
            : a,
        0,
      );

      const sessionWithMostTrainingSlot = sessionsForTrainingDay.at(index);

      if (
        sessionWithMostTrainingSlot!.trainingSlots!.length >=
        td.trainingSlots!.length
      ) {
        uniqueSlots = sessionWithMostTrainingSlot!.trainingSlots!;
      } else {
        uniqueSlots = td.trainingSlots as TemplateTrainingSlot[];
      }
    } else {
      uniqueSlots = td.trainingSlots as TemplateTrainingSlot[];
    }

    return uniqueSlots;
  }

  getTrainingSlotSessionData(
    td: TemplateTrainingDay,
    slot: TemplateTrainingSlot,
  ) {
    let sessionslots: TemplateTrainingSlot[] = [];

    const sessiondates = this.getSessionForLoggedTrainingDay(td);
    if (sessiondates)
      sessiondates.forEach((session: TemplateTrainingDay) => {
        session.trainingSlots?.forEach((sessionslot: TemplateTrainingSlot) => {
          if (
            slot.exerciseId &&
            sessionslot.exerciseId === slot.exerciseId &&
            sessionslot.reps === slot.reps

            //sessionslot.slot == slot.slot
          ) {
            sessionslots.push(sessionslot);
          } else if (
            sessionslot.exerciseId === slot.exerciseId &&
            sessionslot.slot === slot.slot
          ) {
            sessionslots.push(sessionslot);
          }
        });
      });

    return sessionslots;
  }
}
