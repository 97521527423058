import {
  APP_INITIALIZER,
  ErrorHandler,
  NgModule,
  isDevMode,
} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './common/home/home.component';
import { Router, RouterModule, Routes } from '@angular/router';
import { WeightEntryComponent } from './common/weight-entry/weight-entry.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TrainingsheetComponent } from './CustomerView/trainingsheet/trainingsheet.component';
import { ExerciseCreatorComponent } from './CoachView/exercise-creator/exercise-creator.component';
import { AngularFireModule, FIREBASE_OPTIONS } from '@angular/fire/compat';
import { environment } from '../environments/environment';

import { getApp, initializeApp, provideFirebaseApp } from '@angular/fire/app';
import {
  Firestore,
  getFirestore,
  initializeFirestore,
  provideFirestore,
} from '@angular/fire/firestore';
import { TrainingplanCreatorComponent } from './CoachView/trainingplan-creator/trainingplan-creator.component';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacyAutocompleteModule as MatAutocompleteModule } from '@angular/material/legacy-autocomplete';
import { DietsheetComponent } from './CustomerView/dietsheet/dietsheet.component';
import { CalendarComponent } from './common/calendar/calendar.component';
import { AngularFireAuthModule } from '@angular/fire/compat/auth';
import { AngularFirestoreModule } from '@angular/fire/compat/firestore';
import { AngularFireStorageModule } from '@angular/fire/compat/storage';
import { AngularFireDatabaseModule } from '@angular/fire/compat/database';
import { AuthService } from './services/auth.service';
import { LoginComponent } from './common/login/login.component';
import { RegisterComponent } from './common/register/register.component';
import { VerifyEmailComponent } from './common/verify-email/verify-email.component';
import { CustomerNavigationComponent } from './CustomerView/customer-navigation/customer-navigation.component';
import { CustomerDashboardComponent } from './CustomerView/customer-dashboard/customer-dashboard.component';
import { CustomerTrainingplansComponent } from './CustomerView/customer-trainingplans/customer-trainingplans.component';
import { CustomerCheckInsComponent } from './CustomerView/customer-check-ins/customer-check-ins.component';
import { CustomerSettingsComponent } from './CustomerView/customer-settings/customer-settings.component';
import { CustomerProfileComponent } from './CustomerView/customer-profile/customer-profile.component';
import { UploadTaskComponent } from './common/upload-task/upload-task.component';
import { DatepickerComponent } from './common/datepicker/datepicker.component';
import { CommonModule } from '@angular/common';
import { UploadProfilePictureComponent } from './common/upload-profile-picture/upload-profile-picture.component';
import { SimpleModalComponent } from './common/simple-modal/simple-modal.component';
import { CoachNavigationComponent } from './CoachView/coach-navigation/coach-navigation.component';
import { ExerciseLibraryComponent } from './CoachView/exercise-library/exercise-library.component';
import { TrainingplanLibraryComponent } from './CoachView/trainingplan-library/trainingplan-library.component';
import { CustomerLibraryComponent } from './CoachView/customer-library/customer-library.component';
import { CoachDashboardComponent } from './CoachView/coach-dashboard/coach-dashboard.component';
import { CoachCheckInsComponent } from './CoachView/coach-check-ins/coach-check-ins.component';
import { CoachSettingsComponent } from './CoachView/coach-settings/coach-settings.component';
import { ClipboardModule } from 'ngx-clipboard';
import { CoachWeightlogsComponent } from './CoachView/coach-weightlogs/coach-weightlogs.component';
import { SetMacrosModalComponent } from './common/set-macros-modal/set-macros-modal.component';
import { MacrographsComponent } from './common/macrographs/macrographs.component';
import { TrainingplanAssignComponent } from './CoachView/trainingplan-assign/trainingplan-assign.component';
import { CustomerTrainingplanLibraryComponent } from './CustomerView/customer-trainingplan-library/customer-trainingplan-library.component';
import { SlotInputComponent } from './common/slot-input/slot-input.component';
import { TrainingplanLogviewComponent } from './CoachView/trainingplan-logview/trainingplan-logview.component';
import { TrainingplanAssignDynamicComponent } from './CoachView/trainingplan-assign-dynamic/trainingplan-assign-dynamic.component';
import { CoachAssignedTrainigplansComponent } from './CoachView/coach-assigned-trainigplans/coach-assigned-trainigplans.component';
import { NotificationComponent } from './common/notification/notification.component';
import { VideoLinkEditModalComponent } from './common/video-link-edit-modal/video-link-edit-modal.component';
import { NewExercisesModalComponent } from './common/new-exercises-modal/new-exercises-modal.component';
import { ClientguardGuard } from './common/clientguard.guard';
import { CoachesguardGuard } from './common/coachesguard.guard';
import { LoginActiveGuard } from './common/login-active.guard';
import { RECAPTCHA_V3_SITE_KEY, ReCaptchaV3Service } from 'ng-recaptcha';
import * as Sentry from '@sentry/angular-ivy';
import { MiniLineChartComponent } from './CoachView/mini-line-chart/mini-line-chart.component';
import { MiniMacroRadarComponent } from './CoachView/mini-macro-radar/mini-macro-radar.component';
import { MiniMacroPieComponent } from './CoachView/mini-macro-pie/mini-macro-pie.component';
import { SetMacrosDatepickerComponent } from './CoachView/set-macros-datepicker/set-macros-datepicker.component';
import { SetMacrosDatepickerModalComponent } from './common/set-macros-datepicker-modal/set-macros-datepicker-modal.component';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { ExerciseSearchModalComponent } from './CoachView/exercise-search-modal/exercise-search-modal.component';
import { SessionRatingModalComponent } from './common/session-rating-modal/session-rating-modal.component';

import { CustomerManagementComponent } from './CoachView/customer-management/customer-management.component';
import { CustomTrackingComponent } from './CoachView/custom-tracking/custom-tracking.component';
import { CoachAssignedTrackingComponent } from './CoachView/coach-assigned-tracking/coach-assigned-tracking.component';
import { CustomModelModalComponent } from './common/custom-model-modal/custom-model-modal.component';
import { GeneratedRangeSliderComponent } from './common/generated-range-slider/generated-range-slider.component';
import { GeneratedTextInputComponent } from './common/generated-text-input/generated-text-input.component';
import { GeneratedNumberInputComponent } from './common/generated-number-input/generated-number-input.component';
import { WeightlogTableComponent } from './CoachView/weightlog-table/weightlog-table.component';
import { WeightlogTableWithCustomTrackingComponent } from './CoachView/weightlog-table-with-custom-tracking/weightlog-table-with-custom-tracking.component';
import { FilterSearchModelsPipe } from './filter-search-models.pipe';
import { GeneratedBooleanInputComponent } from './common/generated-boolean-input/generated-boolean-input.component';
import { CoachCheckInComponent } from './CoachView/coach-check-in/coach-check-in.component';
import { CheckInDetailViewComponent } from './common/check-in-detail-view/check-in-detail-view.component';
import { TrainingLogSlotDetailViewComponent } from './CoachView/training-log-slot-detail-view/training-log-slot-detail-view.component';
import { TrainingLogSlotDetailSimpleViewComponent } from './CoachView/training-log-slot-detail-simple-view/training-log-slot-detail-simple-view.component';
import { CoachCustomerNotesComponent } from './CoachView/coach-customer-notes/coach-customer-notes.component';
import { CoachCustomerMenuComponent } from './CoachView/coach-customer-menu/coach-customer-menu.component';
import { CoachCustomerNotesCreatorComponent } from './CoachView/coach-customer-notes-creator/coach-customer-notes-creator.component';
import { NotesModalComponent } from './common/notes-modal/notes-modal.component';
import { FilterSearchTermPipe } from './filter-search-term.pipe';
import { NotesModuleComponent } from './common/notes-module/notes-module.component';
import { LongPressDirective } from './long-press.directive';
import { FlyoutMsgMenuComponent } from './common/flyout-msg-menu/flyout-msg-menu.component';
import { CoachDashboardV2Component } from './CoachView/coach-dashboard-v2/coach-dashboard-v2.component';
import { CoachDashboardSideMenuComponent } from './CoachView/coach-dashboard-side-menu/coach-dashboard-side-menu.component';
import { CoachDashboardClientAvatarComponent } from './CoachView/coach-dashboard-client-avatar/coach-dashboard-client-avatar.component';
import { CoachDashboardCheckInHistoryComponent } from './CoachView/coach-dashboard-check-in-history/coach-dashboard-check-in-history.component';
import { CoachDashboardCheckInSelectedComponent } from './CoachView/coach-dashboard-check-in-selected/coach-dashboard-check-in-selected.component';
import { CoachDashboardCheckInWlogPanelComponent } from './CoachView/coach-dashboard-check-in-wlog-panel/coach-dashboard-check-in-wlog-panel.component';
import { WeightlogTableDashboardComponent } from './CoachView/weightlog-table-dashboard/weightlog-table-dashboard.component';
import { WeightlogLineChartDashboardComponent } from './CoachView/weightlog-line-chart-dashboard/weightlog-line-chart-dashboard.component';
import { WeightlogMacroPiesDashboardComponent } from './CoachView/weightlog-macro-pies-dashboard/weightlog-macro-pies-dashboard.component';
import { TrainingLogPanelDashboardComponent } from './CoachView/training-log-panel-dashboard/training-log-panel-dashboard.component';
import { FlyoutMsgDashboardMenuComponent } from './CoachView/flyout-msg-dashboard-menu/flyout-msg-dashboard-menu.component';
import { WeightlogTableDashboardV2Component } from './CoachView/weightlog-table-dashboard-v2/weightlog-table-dashboard-v2.component';
import { WeightlogCustomtableDashboardComponent } from './CoachView/weightlog-customtable-dashboard/weightlog-customtable-dashboard.component';
import { WeightlogCustombodyDashboardComponent } from './CoachView/weightlog-custombody-dashboard/weightlog-custombody-dashboard.component';
import { WeightlogCustomheaderDashboardComponent } from './CoachView/weightlog-customheader-dashboard/weightlog-customheader-dashboard.component';
import { SetMacrosModuleComponent } from './CoachView/set-macros-module/set-macros-module.component';
import { HoverOverImgDirective } from './hover-over-img.directive';
import { MacroEntryModalComponent } from './common/macro-entry-modal/macro-entry-modal.component';
import { TesterComponent } from './common/tester/tester.component';
import { VoiceRecorderComponent } from './common/voice-recorder/voice-recorder.component';
import { VoicePlayerComponent } from './common/voice-player/voice-player.component';
import { CheckinMessageComponent } from './common/checkin-message/checkin-message.component';
import { CheckinFormAnswerComponent } from './common/checkin-form-answer/checkin-form-answer.component';
import { ExerciseSetupModalComponent } from './common/exercise-setup-modal/exercise-setup-modal.component';
import { GdprComponent } from './common/gdpr/gdpr.component';
import { AgbComponent } from './common/agb/agb.component';
import { ImpressumComponent } from './common/impressum/impressum.component';
import { ContactComponent } from './common/contact/contact.component';
import { AdminNavigationComponent } from './admin/admin-navigation/admin-navigation.component';
import { CSVImportComponent } from './CoachView/csvimport/csvimport.component';
import { NgxSliderModule } from 'ngx-slider-v2';
import { NgxCsvParserModule } from 'ngx-csv-parser';
import { ImgFullscreenModalComponent } from './common/img-fullscreen-modal/img-fullscreen-modal.component';
import { CheckinSubmenuComponent } from './common/checkin-submenu/checkin-submenu.component';
import { SetOffDayMacrosModuleCoachComponent } from './CoachView/set-off-day-macros-module-coach/set-off-day-macros-module-coach.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { SetOffDayMacrosModalComponent } from './CoachView/set-off-day-macros-modal/set-off-day-macros-modal.component';
import { ShowOffDayMacrosModuleComponent } from './CustomerView/show-off-day-macros-module/show-off-day-macros-module.component';
import { CalendarV2Component } from './common/calendar-v2/calendar-v2.component';
import { CalendarDayModalComponent } from './common/calendar-day-modal/calendar-day-modal.component';
import { CalendarNewEntryModalComponent } from './common/calendar-new-Cal-entry-modal/calendar-new-Cal-entry-modal.component';
import { BarMacroChartsComponent } from './common/bar-macro-charts/bar-macro-charts.component';
import { NotificationService } from './services/notification.service';
import { USE_EMULATOR as USE_FIRESTORE_EMULATOR } from '@angular/fire/compat/firestore';
import { USE_EMULATOR as USE_AUTH_EMULATOR } from '@angular/fire/compat/auth';
import {
  AngularFireFunctionsModule,
  USE_EMULATOR as USE_FUNCTIONS_EMULATOR,
  REGION,
} from '@angular/fire/compat/functions';

import {
  AngularFireMessaging,
  AngularFireMessagingModule,
} from '@angular/fire/compat/messaging';
import { connectFirestoreEmulator } from 'firebase/firestore';
import {
  ReCaptchaV3Provider,
  initializeAppCheck,
  provideAppCheck,
} from '@angular/fire/app-check';
import { ServiceWorkerModule } from '@angular/service-worker';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { CoachesFeaturelistComponent } from './common/coaches-featurelist/coaches-featurelist.component';
import { CustomerFeaturelistComponent } from './common/customer-featurelist/customer-featurelist.component';
import { StatisticsComponent } from './CustomerView/tracking-statistics/statistics.component';
import { StatisticsWlogTableComponent } from './CustomerView/tracking-statistics/tracking-statistics-wlog-table/statistics-wlog-table.component';
import { StatisticsWlogTableheaderComponent } from './CustomerView/tracking-statistics/tracking-statistics-wlog-table/statistics-wlog-tableheader.component';
import { StatisticsWlogTablebodyComponent } from './CustomerView/tracking-statistics/tracking-statistics-wlog-table/statistics-wlog-tablebody.component';
import { DurationDropdownComponent } from './CustomerView/tracking-statistics/duration-dropdown.component';
import { StatisticsMacroTrackingTableComponent } from './CustomerView/tracking-statistics/tracking-statistics-macro-tracking-table/statistics-macro-tracking-table.component';
import { StatisticsMacroTrackingTableheaderComponent } from './CustomerView/tracking-statistics/tracking-statistics-macro-tracking-table/statistics-macro-tracking-tableheader.component';
import { StatisticsMacroTrackingTablebodyComponent } from './CustomerView/tracking-statistics/tracking-statistics-macro-tracking-table/statistics-macro-tracking-tablebody.component';
import { StatisticsCtmTableComponent } from './CustomerView/tracking-statistics/tracking-statistics-ctm-table/statistics-ctm-table.component';
import { StatisticsCtmTableheaderComponent } from './CustomerView/tracking-statistics/tracking-statistics-ctm-table/statistics-ctm-tableheader.component';
import { StatisticsCtmTablebodyComponent } from './CustomerView/tracking-statistics/tracking-statistics-ctm-table/statistics-ctm-tablebody.component';
import { TrackingTrainingComponent } from './CustomerView/tracking-training/tracking-training.component';
import { TrainingTableDashboardComponent } from './CustomerView/tracking-training/training-table-dashboard.component';
import { AdminUserViewComponent } from './admin/admin-user-view.component';
import { AdminDashboardComponent } from './admin/admin-dashboard.component';
import { AdminguardGuard } from './common/adminguard.guard';
import { AdminDisplayUserComponent } from './admin/admin-display-user.component';
import { AdminUserFormComponent } from './admin/admin-user-form.component';
import { FullCalendarModule } from '@fullcalendar/angular';
import { CalendarFullComponent } from './common/calendar-full/calendar-full.component';
import { CalendarFullEventModalComponent } from './common/calendar-full-event-modal/calendar-full-event-modal.component';

import { ModalWeightEntryComponent } from './common/calendar-full-event-modal/modal-weight-entry.component';
import { ModalEventComponent } from './common/calendar-full-event-modal/modal-event.component';
import { ModalCtmComponent } from './common/calendar-full-event-modal/modal-ctm.component';
import { ModalMacrosComponent } from './common/calendar-full-event-modal/modal-macros.component';
import { CoachDashboardDataTablePanelComponent } from './CoachView/coach-dashboard-data-table-panel/coach-dashboard-data-table-panel.component';
import { ModalCoachSpecsComponent } from './common/calendar-full-event-modal/modal-coach-specs.component';
import { CoachSpecsentryModalComponent } from './CoachView/coach-specsentry-modal/coach-specsentry-modal.component';
import { CalendarMultieventModalComponent } from './common/calendar-multiday-modal/calendar-multiday-modal.component';
import { CustomerSpecViewComponent } from './CustomerView/customer-dashboard/customer-spec-view.component';
import { DisplayMacrosModuleComponent } from './common/display-macros-module/display-macros-module.component';
import { DateRangePickerComponent } from './common/date-range-picker/date-range-picker.component';
import { DatePickerComponent } from './common/date-range-picker/date-picker.component';
import { UploadFilesTaskComponent } from './common/upload-files-task/upload-files-task.component';
import { UploadBrandPictureComponent } from './common/upload-brand-picture/upload-brand-picture.component';
import { MealplanerComponent } from './CoachView/mealplaner/mealplaner.component';
import { MealplanCreatorComponent } from './CoachView/mealplaner/mealplan-creator.component';
import { MealdayCreatorComponent } from './CoachView/mealplaner/mealday-creator.component';
import { MealCreatorComponent } from './CoachView/mealplaner/meal-creator.component';
import { HttpClientModule } from '@angular/common/http';
import { NewIngredientModalComponent } from './CoachView/mealplaner/new-ingredient-modal.component';
import { IngredientLibComponent } from './CoachView/mealplaner/ingredient-lib.component';
import { MealdayStatsComponent } from './CoachView/mealplaner/mealday-stats.component';
import { MealsLibComponent } from './CoachView/mealplaner/meals-lib.component';
import { EditMealModalComponent } from './CoachView/mealplaner/edit-meal-modal.component';
import { LoaderModalComponent } from './CoachView/mealplaner/loader-modal.component';
import { ClientMealplansComponent } from './CoachView/mealplaner/client-mealplans.component';
import { MealplanDashboardViewComponent } from './CustomerView/mealplan-dashboard-view/mealplan-dashboard-view.component';
import { MealplanDetailViewComponent } from './CustomerView/mealplan-detail-view/mealplan-detail-view.component';
import { CustomCheckinFormsComponent } from './CoachView/checkins/custom-checkin-forms/custom-checkin-forms.component';
import { CustomCheckinFormsCreatorComponent } from './CoachView/checkins/custom-checkin-forms/custom-checkin-forms-creator.component';
import { CustomCheckinInputsCreatorComponent } from './CoachView/checkins/custom-checkin-forms/custom-checkin-inputs-creator.component';
import { CustomCheckinInputsViewComponent } from './CoachView/checkins/custom-checkin-forms/custom-checkin-inputs-view.component';
import { AssignedCheckinFormComponent } from './CoachView/checkins/custom-checkin-forms/assigned-checkin-form.component';
import { CheckinCustomModelsFormComponent } from './CustomerView/checkin-custom-models-form/checkin-custom-models-form.component';
import { CheckinGeneratedTextInputComponent } from './CustomerView/checkin-custom-models-form/checkin-generated-text-input.component';
import { CheckinGeneratedNumberInputComponent } from './CustomerView/checkin-custom-models-form/checkin-generated-number-input.component';
import { CheckinGeneratedRangeInputComponent } from './CustomerView/checkin-custom-models-form/checkin-generated-range-input.component';
import { DisplayCheckinFormDashboardComponent } from './CoachView/checkins/display-dashboard/display-checkin-form-dashboard.component';
import { DisplayDashboardCheckinTextComponent } from './CoachView/checkins/display-dashboard/display-dashboard-checkin-text.component';
import { DisplayDashboardCheckinNumberComponent } from './CoachView/checkins/display-dashboard/display-dashboard-checkin-number.component';
import { DisplayDashboardCheckinRangeComponent } from './CoachView/checkins/display-dashboard/display-dashboard-checkin-range.component';
import { CustomCheckinInputsEditComponent } from './CoachView/checkins/custom-checkin-forms/custom-checkin-inputs-edit.component';
import { TimerComponent } from './common/timer/timer.component';
import { CustomerPanelComponent } from './CoachView/customer-panel/customer-panel.component';
import { ModelGeneratorComponent } from './CoachView/trainingplans/model-generator/model-generator.component';
import { ModelDashboardComponent } from './CoachView/trainingplans/model-generator/model-dashboard.component';
import { ModelInputCreatorComponent } from './CoachView/trainingplans/model-generator/model-input-creator.component';
import { TemplateTrainingSlotComponent } from './CoachView/trainingplans/template-training-slot/template-training-slot.component';
import { TemplateTrainingDayComponent } from './CoachView/trainingplans/template-training-day/template-training-day.component';
import { TemplateTrainingMesocycleComponent } from './CoachView/trainingplans/template-training-mesocycle/template-training-mesocycle.component';
import { TemplateTrainingPlanComponent } from './CoachView/trainingplans/template-training-plan/template-training-plan.component';
import { TemplateSlotGenerationComponent } from './CoachView/trainingplans/template-slot-generation/template-slot-generation.component';
import { NumberSlotComponent } from './CoachView/trainingplans/template-slot-generation/slotTypes/number-slot.component';
import { TimeSlotComponent } from './CoachView/trainingplans/template-slot-generation/slotTypes/time-slot.component';
import { TargetSlotComponent } from './CoachView/trainingplans/template-slot-generation/slotTypes/target-slot.component';
import { WeightlogCustomAverageDashboardComponent } from './CoachView/weightlog-custom-average-dashboard/weightlog-custom-average-dashboard.component';
import { NotFoundComponent } from './common/errorPages/not-found/not-found.component';
import { SimpleLoadingModalComponent } from './common/modals/simple-loading-modal/simple-loading-modal.component';
import { VerifyGuard } from './common/verifyguard.guard';
import { ClientsOverviewComponent } from './CoachView/clients-overview/clients-overview.component';
import { ClientPanelComponent } from './CoachView/clients-overview/panels/client-panel.component';
import { ClientPendingPanelComponent } from './CoachView/clients-overview/panels/client-pending-panel.component';
import { CoachingSpotPanelComponent } from './CoachView/clients-overview/panels/coaching-spot-panel.component';
import { CoachPaymentPanelComponent } from './CoachView/clients-overview/panels/coach-payment-panel.component';
import { ClientRegistrationPanelComponent } from './CoachView/clients-overview/panels/client-registration-panel.component';
import { ToggleSwitchComponent } from './common/toggle-switch/toggle-switch.component';
import { SimulateClientComponent } from './admin/simulate-client/simulate-client.component';

const routes: Routes = [
  { path: '', redirectTo: 'home', pathMatch: 'full' },
  {
    path: 'home',
    component: HomeComponent,
    canActivate: [LoginActiveGuard],
  },
  {
    path: 'agb',
    component: AgbComponent,
    canActivate: [LoginActiveGuard],
  },
  {
    path: 'gdpr',
    component: GdprComponent,
    canActivate: [LoginActiveGuard],
  },
  {
    path: 'legal-notice',
    component: ImpressumComponent,
    canActivate: [LoginActiveGuard],
  },

  {
    path: 'contact',
    component: ContactComponent,
    canActivate: [LoginActiveGuard],
  },

  { path: 'login', component: LoginComponent, canActivate: [LoginActiveGuard] },
  {
    path: 'register/page-not-found',
    component: NotFoundComponent,
    canActivate: [LoginActiveGuard],
  },
  {
    path: 'register/:id',
    component: RegisterComponent,
    canActivate: [LoginActiveGuard],
  },

  {
    path: 'verify-email',
    component: VerifyEmailComponent,
    canActivate: [VerifyGuard],
  },

  {
    path: 'tester/:id',
    component: TesterComponent,
    canActivate: [LoginActiveGuard],
  },

  {
    path: 'dashboard',
    component: CustomerDashboardComponent,
    canActivate: [ClientguardGuard],
  },
  {
    path: 'weight-entry',
    component: WeightEntryComponent,
    canActivate: [ClientguardGuard],
  },

  {
    path: 'tracking-statistics',
    component: StatisticsComponent,
    canActivate: [ClientguardGuard],
  },

  {
    path: 'tracking-training',
    component: TrackingTrainingComponent,
    canActivate: [ClientguardGuard],
  },
  {
    path: 'dietsheet',
    component: DietsheetComponent,
    canActivate: [ClientguardGuard],
  },
  {
    path: 'trainingsheet',
    component: TrainingsheetComponent,
    canActivate: [ClientguardGuard],
  },
  {
    path: 'weightlog',
    component: CalendarComponent,
    canActivate: [ClientguardGuard],
  },

  {
    path: 'trainingplans',
    component: CustomerTrainingplanLibraryComponent,
    canActivate: [ClientguardGuard],
  },
  {
    path: 'trainingplans/:id',
    component: CustomerTrainingplansComponent,
    canActivate: [ClientguardGuard],
  },

  {
    path: 'checkins',
    component: CustomerCheckInsComponent,
    canActivate: [ClientguardGuard],
  },
  {
    path: 'settings',
    component: CustomerSettingsComponent,
    canActivate: [ClientguardGuard],
  },
  {
    path: 'profile',
    component: CustomerProfileComponent,
    canActivate: [ClientguardGuard],
  },
  {
    path: 'calendar-old',
    component: CalendarV2Component,
    canActivate: [ClientguardGuard],
  },
  {
    path: 'calendar',
    component: CalendarFullComponent,
    canActivate: [ClientguardGuard],
  },
  {
    path: 'mealplan/:id',
    component: MealplanDetailViewComponent,
    canActivate: [ClientguardGuard],
  },

  {
    path: 'exercise-creator/new',
    component: ExerciseCreatorComponent,
    canActivate: [CoachesguardGuard],
  },
  {
    path: 'exercise-creator/edit',
    component: ExerciseCreatorComponent,
    canActivate: [CoachesguardGuard],
  },
  {
    path: 'exercise-creator/:id',
    component: ExerciseCreatorComponent,
    canActivate: [CoachesguardGuard],
  },
  {
    path: 'exercise-library',
    component: ExerciseLibraryComponent,
    canActivate: [CoachesguardGuard],
  },
  {
    path: 'csv-import',
    component: CSVImportComponent,
    canActivate: [CoachesguardGuard],
  },
  {
    path: 'trainingplan-library',
    component: TrainingplanLibraryComponent,
    canActivate: [CoachesguardGuard],
  },

  {
    path: 'trainingplan-creator/new',
    component: TrainingplanCreatorComponent,
    canActivate: [CoachesguardGuard],
  },
  {
    path: 'trainingplan-creator/:id',
    component: TrainingplanCreatorComponent,
    canActivate: [CoachesguardGuard],
  },
  {
    path: 'trainingplan-assign/new',
    component: TrainingplanAssignDynamicComponent,

    canActivate: [CoachesguardGuard],
  },
  {
    path: 'trainingplan-assign/:id',
    component: TrainingplanAssignDynamicComponent,
    canActivate: [CoachesguardGuard],
  },
  // New Feature Dev.
  //
  //
  /*
  {
    path: 'model-dashboard',
    component: ModelDashboardComponent,
    canActivate: [CoachesguardGuard],
  },

  {
    path: 'model-generator/new',
    component: ModelGeneratorComponent,
    canActivate: [CoachesguardGuard],
  },
  {
    path: 'model-generator/:id',
    component: ModelGeneratorComponent,
    canActivate: [CoachesguardGuard],
  },

  {
    path: 'tp-generator/new',
    component: TemplateTrainingPlanComponent,
    canActivate: [CoachesguardGuard],
  },
  */
  {
    path: 'mealplaner',
    component: MealplanerComponent,
    canActivate: [CoachesguardGuard],
  },
  {
    path: 'mealplaner/new',
    component: MealplanCreatorComponent,
    canActivate: [CoachesguardGuard],
  },
  {
    path: 'mealplaner/:id',
    component: MealplanCreatorComponent,
    canActivate: [CoachesguardGuard],
  },
  {
    path: 'mealplaner/daycreator/:id',
    component: MealdayCreatorComponent,
    canActivate: [CoachesguardGuard],
  },

  {
    path: 'mealplaner/assign/client',
    component: ClientMealplansComponent,
    canActivate: [CoachesguardGuard],
  },

  {
    path: 'mealplaner/daycreator/new',
    component: MealdayCreatorComponent,
    canActivate: [CoachesguardGuard],
  },
  {
    path: 'mealplaner/daycreator/mealcreator/new',
    component: MealCreatorComponent,
    canActivate: [CoachesguardGuard],
  },
  {
    path: 'mealplaner/daycreator/mealcreator/:id',
    component: MealCreatorComponent,
    canActivate: [CoachesguardGuard],
  },
  {
    path: 'ingredient-lib',
    component: IngredientLibComponent,
    canActivate: [CoachesguardGuard],
  },
  {
    path: 'meals-lib',
    component: MealsLibComponent,
    canActivate: [CoachesguardGuard],
  },

  {
    path: 'clients',
    redirectTo: 'clients-overview',
    pathMatch: 'full',
  },
  {
    path: 'clients-overview',
    component: ClientsOverviewComponent,
    canActivate: [CoachesguardGuard],
  },
  {
    path: 'clients-management',
    component: CustomerManagementComponent,
    canActivate: [CoachesguardGuard],
  },
  {
    path: 'coach-dashboard',
    component: CoachDashboardV2Component,
    canActivate: [CoachesguardGuard],
  },

  {
    path: 'coach-checkins',
    component: CoachCheckInComponent,
    canActivate: [CoachesguardGuard],
  },
  {
    path: 'coach-settings',
    component: CoachSettingsComponent,
    canActivate: [CoachesguardGuard],
  },
  {
    path: 'coach-weightlogs',
    component: CoachWeightlogsComponent,
    canActivate: [CoachesguardGuard],
  },
  {
    path: 'coach-custom-checkin-form',
    component: CustomCheckinFormsComponent,
    canActivate: [CoachesguardGuard],
  },

  {
    path: 'coach-custom-checkin-form/:id',
    component: CustomCheckinFormsCreatorComponent,
    canActivate: [CoachesguardGuard],
  },
  {
    path: 'coach-custom-checkin-form/new',
    component: CustomCheckinFormsCreatorComponent,
    canActivate: [CoachesguardGuard],
  },

  {
    path: 'coach-assigned-checkin-form',
    component: AssignedCheckinFormComponent,
    canActivate: [CoachesguardGuard],
  },

  {
    path: 'coach-custom-tracking',
    component: CustomTrackingComponent,
    canActivate: [CoachesguardGuard],
  },
  {
    path: 'coach-assigned-tracking',
    component: CoachAssignedTrackingComponent,
    canActivate: [CoachesguardGuard],
  },

  {
    path: 'coach-traininglogs',
    component: TrainingplanLogviewComponent,
    canActivate: [CoachesguardGuard],
  },
  {
    path: 'coach-assigned-trainingplans',
    component: CoachAssignedTrainigplansComponent,
    canActivate: [CoachesguardGuard],
  },

  {
    path: 'notes',
    component: CoachCustomerNotesComponent,
    canActivate: [CoachesguardGuard],
  },

  {
    path: 'admin/dashboard',
    component: AdminDashboardComponent,
    canActivate: [AdminguardGuard],
  },

  {
    path: 'admin/useroverview',
    component: AdminUserViewComponent,
    canActivate: [AdminguardGuard],
  },

  {
    path: '#',
    redirectTo: 'home',
  },

  {
    path: '**',
    redirectTo: 'home',
  },
];

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    WeightEntryComponent,
    DietsheetComponent,
    TrainingsheetComponent,
    ExerciseCreatorComponent,
    TrainingplanCreatorComponent,
    CalendarComponent,
    LoginComponent,
    RegisterComponent,
    VerifyEmailComponent,
    CustomerNavigationComponent,
    CustomerTrainingplansComponent,
    CustomerCheckInsComponent,
    CustomerSettingsComponent,
    CustomerProfileComponent,
    UploadTaskComponent,
    DatepickerComponent,
    UploadProfilePictureComponent,
    SimpleModalComponent,
    CustomerDashboardComponent,
    CoachNavigationComponent,
    ExerciseLibraryComponent,
    TrainingplanLibraryComponent,
    CustomerLibraryComponent,
    CoachDashboardComponent,
    CoachCheckInsComponent,
    CoachSettingsComponent,
    CustomerLibraryComponent,
    CoachWeightlogsComponent,
    SetMacrosModalComponent,
    MacrographsComponent,
    TrainingplanAssignComponent,
    CustomerTrainingplanLibraryComponent,
    SlotInputComponent,
    TrainingplanLogviewComponent,
    TrainingplanAssignDynamicComponent,
    CoachAssignedTrainigplansComponent,
    NotificationComponent,
    VideoLinkEditModalComponent,
    NewExercisesModalComponent,
    MiniLineChartComponent,
    MiniMacroRadarComponent,
    MiniMacroPieComponent,
    SetMacrosDatepickerComponent,
    SetMacrosDatepickerModalComponent,
    ExerciseSearchModalComponent,
    SessionRatingModalComponent,
    CustomerManagementComponent,
    CustomTrackingComponent,
    CoachAssignedTrackingComponent,
    CustomModelModalComponent,
    GeneratedRangeSliderComponent,
    GeneratedTextInputComponent,
    GeneratedNumberInputComponent,
    WeightlogTableComponent,
    WeightlogTableWithCustomTrackingComponent,
    FilterSearchModelsPipe,
    GeneratedBooleanInputComponent,
    CoachCheckInComponent,
    CheckInDetailViewComponent,
    TrainingLogSlotDetailViewComponent,
    TrainingLogSlotDetailSimpleViewComponent,
    CoachCustomerNotesComponent,
    CoachCustomerMenuComponent,
    CoachCustomerNotesCreatorComponent,
    NotesModalComponent,
    FilterSearchTermPipe,
    NotesModuleComponent,
    LongPressDirective,
    FlyoutMsgMenuComponent,
    CoachDashboardV2Component,
    CoachDashboardSideMenuComponent,
    CoachDashboardClientAvatarComponent,
    CoachDashboardCheckInHistoryComponent,
    CoachDashboardCheckInSelectedComponent,
    CoachDashboardCheckInWlogPanelComponent,
    WeightlogTableDashboardComponent,
    WeightlogLineChartDashboardComponent,
    WeightlogMacroPiesDashboardComponent,
    TrainingLogPanelDashboardComponent,
    FlyoutMsgDashboardMenuComponent,
    WeightlogTableDashboardV2Component,
    WeightlogCustomtableDashboardComponent,
    WeightlogCustombodyDashboardComponent,
    WeightlogCustomheaderDashboardComponent,
    SetMacrosModuleComponent,
    HoverOverImgDirective,
    MacroEntryModalComponent,

    TesterComponent,
    VoiceRecorderComponent,
    VoicePlayerComponent,
    CheckinMessageComponent,
    CheckinFormAnswerComponent,
    ExerciseSetupModalComponent,
    GdprComponent,
    AgbComponent,
    ImpressumComponent,
    ContactComponent,

    AdminNavigationComponent,
    CSVImportComponent,
    ImgFullscreenModalComponent,
    CheckinSubmenuComponent,
    SetOffDayMacrosModuleCoachComponent,
    SetOffDayMacrosModalComponent,
    ShowOffDayMacrosModuleComponent,
    CalendarV2Component,
    CalendarDayModalComponent,
    CalendarNewEntryModalComponent,
    BarMacroChartsComponent,
    CoachesFeaturelistComponent,
    CustomerFeaturelistComponent,
    StatisticsComponent,
    StatisticsWlogTableComponent,
    StatisticsWlogTableheaderComponent,
    StatisticsWlogTablebodyComponent,
    DurationDropdownComponent,
    StatisticsMacroTrackingTableComponent,
    StatisticsMacroTrackingTableheaderComponent,
    StatisticsMacroTrackingTablebodyComponent,
    StatisticsCtmTableComponent,
    StatisticsCtmTableheaderComponent,
    StatisticsCtmTablebodyComponent,
    TrackingTrainingComponent,
    TrainingTableDashboardComponent,
    AdminUserViewComponent,
    AdminDashboardComponent,
    AdminDisplayUserComponent,
    AdminUserFormComponent,
    CalendarFullComponent,
    CalendarFullEventModalComponent,

    ModalWeightEntryComponent,
    ModalEventComponent,
    ModalCtmComponent,
    ModalMacrosComponent,
    CoachDashboardDataTablePanelComponent,
    ModalCoachSpecsComponent,
    CoachSpecsentryModalComponent,
    CalendarMultieventModalComponent,
    CustomerSpecViewComponent,
    DisplayMacrosModuleComponent,
    DateRangePickerComponent,
    DatePickerComponent,
    UploadFilesTaskComponent,
    UploadBrandPictureComponent,
    MealplanerComponent,
    MealplanCreatorComponent,
    MealdayCreatorComponent,
    MealCreatorComponent,
    NewIngredientModalComponent,
    IngredientLibComponent,
    MealdayStatsComponent,
    MealsLibComponent,
    EditMealModalComponent,
    LoaderModalComponent,
    ClientMealplansComponent,
    MealplanDashboardViewComponent,
    MealplanDetailViewComponent,
    CustomCheckinFormsComponent,
    CustomCheckinFormsCreatorComponent,
    CustomCheckinInputsCreatorComponent,
    CustomCheckinInputsViewComponent,
    AssignedCheckinFormComponent,
    CheckinCustomModelsFormComponent,
    CheckinGeneratedTextInputComponent,
    CheckinGeneratedNumberInputComponent,
    CheckinGeneratedRangeInputComponent,
    DisplayCheckinFormDashboardComponent,
    DisplayDashboardCheckinTextComponent,
    DisplayDashboardCheckinNumberComponent,
    DisplayDashboardCheckinRangeComponent,
    CustomCheckinInputsEditComponent,
    TimerComponent,
    CustomerPanelComponent,
    ModelGeneratorComponent,
    ModelDashboardComponent,
    ModelInputCreatorComponent,
    TemplateTrainingSlotComponent,
    TemplateTrainingDayComponent,
    TemplateTrainingMesocycleComponent,
    TemplateTrainingPlanComponent,
    TemplateSlotGenerationComponent,
    NumberSlotComponent,
    TimeSlotComponent,
    TargetSlotComponent,
    WeightlogCustomAverageDashboardComponent,
    NotFoundComponent,
    SimpleLoadingModalComponent,
    ClientsOverviewComponent,
    ClientPanelComponent,
    ClientPendingPanelComponent,
    CoachingSpotPanelComponent,
    CoachPaymentPanelComponent,
    ClientRegistrationPanelComponent,
    ToggleSwitchComponent,
    SimulateClientComponent,
  ],
  imports: [
    BrowserModule,
    ReactiveFormsModule,
    FormsModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' }),

    provideFirebaseApp(() => initializeApp(environment.firebaseConfig)),
    provideFirestore(() => {
      let firestore: Firestore;
      if (environment.useEmulators) {
        firestore = initializeFirestore(getApp(), {
          experimentalForceLongPolling: true,
        });

        connectFirestoreEmulator(firestore, 'localhost', 8080);
      } else {
        firestore = getFirestore();
      }
      return firestore;
    }),
    MatFormFieldModule,
    MatAutocompleteModule,

    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFireMessagingModule,
    AngularFireAuthModule,
    AngularFirestoreModule,
    AngularFireStorageModule,
    AngularFireDatabaseModule,
    AngularFireFunctionsModule,
    ClipboardModule,
    NgxSkeletonLoaderModule,
    NgxSliderModule,
    NgxCsvParserModule,
    MatSnackBarModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      registrationStrategy: 'registerWhenStable:30000',
    }),
    FullCalendarModule,
    HttpClientModule,
  ],
  providers: [
    NotificationService,
    ReCaptchaV3Service,
    { provide: RECAPTCHA_V3_SITE_KEY, useValue: environment.recaptcha.sitekey },
    { provide: FIREBASE_OPTIONS, useValue: environment.firebaseConfig },
    AuthService,
    /*
    {
      provide: USE_FIRESTORE_EMULATOR,
      useValue: environment.useEmulators ? ['localhost', 8080] : undefined,
    },
*/
    {
      provide: USE_FUNCTIONS_EMULATOR,
      useValue: environment.useEmulators ? ['localhost', 5001] : undefined,
    },
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: true,
      }),
    },
    { provide: REGION, useValue: 'europe-west1' },

    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
