import { ThisReceiver } from '@angular/compiler';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { Exercise } from 'src/app/core/thecoach';
import { ExerciseService } from 'src/app/services/exercise.service';

@Component({
  selector: 'app-exercise-library',
  template: `
    <div class="py-4 flex w-full justify-between">
      <div class="flex w-11/12 flex-col">
        <label
          for="search"
          class="ml-1 block text-sm font-medium leading-6 text-gray-900"
          >Search by Name</label
        >
        <div class="mt-2">
          <input
            type="search"
            name="search"
            id="search"
            [(ngModel)]="searchNameValue"
            (keyup)="onKeyDownSearch($event)"
            (input)="onClearSearch()"
            class="block w-3/12 rounded-md border-0
          py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300
          placeholder:text-gray-400 focus:ring-2 focus:ring-inset
          focus:ring-indigo-600 sm:text-sm sm:leading-6"
            placeholder="Enter Exercise Name..."
          />
        </div>
      </div>

      <button
        *ngIf="checkForDuplicates()"
        type="button"
        (click)="deleteDuplicates()"
        class="flex items-center  mr-8 h-fit rounded-md border border-transparent bg-teal-600 px-3 py-2 text-sm font-medium leading-4 text-white shadow-sm hover:bg-teal-700 focus:outline-none focus:ring-2 focus:ring-teal-500 focus:ring-offset-2"
      >
        Merge
      </button>

      <button
        *ngIf="!this.newExerciseToggle"
        type="button"
        (click)="onNewExercise()"
        class="flex items-center h-fit rounded-md border border-transparent bg-teal-600 px-3 py-2 text-sm font-medium leading-4 text-white shadow-sm hover:bg-teal-700 focus:outline-none focus:ring-2 focus:ring-teal-500 focus:ring-offset-2"
      >
        Add Exercise
      </button>

      <button
        *ngIf="this.newExerciseToggle"
        type="button"
        (click)="onNewExercise()"
        class="inline-flex items-center rounded-md border border-transparent bg-yellow-600 px-3 py-2 text-sm font-medium leading-4 text-white shadow-sm hover:bg-yellow-700 focus:outline-none focus:ring-2 focus:ring-yellow-500 focus:ring-offset-2"
      >
        Cancel Edit
      </button>
    </div>

    <div *ngIf="this.newExerciseToggle" class="w-5/6 mx-auto">
      <app-exercise-creator
        [id]="exerciseID"
        (formSaved)="onFormSaved($event)"
      ></app-exercise-creator>
    </div>

    <div class="px-4 sm:px-6 lg:px-8">
      <div class="mt-8 flow-root">
        <div class="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div
            class="flex flex-col justify-center w-full py-2 align-middle sm:px-6 lg:px-8"
          >
            <table class="w-full divide-y divide-gray-300 table-fixed">
              <thead>
                <tr>
                  <th
                    scope="col"
                    class="w-[10rem] py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0"
                  >
                    <a
                      class="flex flex-row cursor-pointer"
                      (click)="onTableHeaderClick('Muscle Group')"
                    >
                      Muscle Group
                      <!-- Active: "bg-gray-200 text-gray-900 group-hover:bg-gray-300", Not Active: "invisible text-gray-400 group-hover:visible group-focus:visible" -->
                      <span
                        [ngClass]="{
                          'rotate-180': muscleGroupOrderToggle,
                        }"
                        class="ml-2 flex-none rounded bg-gray-100 text-gray-900 group-hover:bg-gray-200"
                      >
                        <svg
                          class="h-5 w-5"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                          aria-hidden="true"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z"
                            clip-rule="evenodd"
                          />
                        </svg>
                      </span>
                    </a>
                  </th>
                  <th
                    scope="col"
                    class="w-[15rem] px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                  >
                    <a
                      class="flex flex-row cursor-pointer"
                      (click)="onTableHeaderClick('Name')"
                    >
                      Name
                      <!-- Active: "bg-gray-200 text-gray-900 group-hover:bg-gray-300", Not Active: "invisible text-gray-400 group-hover:visible group-focus:visible" -->
                      <span
                        [ngClass]="{
                          'rotate-180': nameOrderToggle,
                        }"
                        class="ml-2 flex-none rounded bg-gray-100 text-gray-900 group-hover:bg-gray-100"
                      >
                        <svg
                          class="h-5 w-5"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                          aria-hidden="true"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z"
                            clip-rule="evenodd"
                          />
                        </svg>
                      </span>
                    </a>
                  </th>
                  <th
                    scope="col"
                    class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                  >
                    Description
                  </th>
                  <th
                    scope="col"
                    class="w-[10rem] py-3.5 text-left text-sm font-semibold text-gray-900"
                  >
                    Id
                  </th>
                  <th
                    scope="col"
                    class="relative w-[4rem] py-3.5 pl-3 pr-4 sm:pr-0 text-sm font-semibold text-gray-900"
                  >
                    Edit
                  </th>
                  <th
                    scope="col"
                    class="relative w-[4rem] py-3.5 pl-3 pr-4 sm:pr-0 text-sm font-semibold text-gray-900"
                  >
                    Delete
                  </th>
                </tr>
              </thead>
              <tbody class="divide-y divide-gray-200">
                <tr *ngFor="let exercise of displayExercises">
                  <td
                    class="whitespace-wrap py-4 pl-4 pr-3 text-sm text-gray-500 sm:pl-0"
                  >
                    {{ exercise.muscleGroup }}
                  </td>
                  <td class="whitespace-wrap px-3 py-4 text-sm text-gray-500">
                    {{ exercise.exerciseName }}
                  </td>
                  <td class="whitespace-wrap px-3 py-4 text-sm text-gray-500">
                    {{ exercise.exerciseDescription }}
                  </td>
                  <td
                    class="whitespace-wrap w-fit py-4 text-xs text-gray-400 italic truncate"
                  >
                    {{ exercise.id }}
                  </td>

                  <td
                    class="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-0"
                  >
                    <button
                      [disabled]="this.newExerciseToggle"
                      type="button"
                      (click)="onEditExercise(exercise.id!)"
                      class="inline-flex items-center rounded-md border border-transparent bg-teal-600 px-3 py-2 text-sm font-medium leading-4 text-white shadow-sm hover:bg-teal-700 focus:outline-none focus:ring-2 focus:ring-teal-500 focus:ring-offset-2 disabled:opacity-50"
                    >
                      Edit
                    </button>
                  </td>
                  <td
                    class="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-0"
                  >
                    <button
                      [disabled]="this.newExerciseToggle"
                      type="button"
                      (click)="onDeleteExercise(exercise.id!)"
                      class="inline-flex items-center rounded-md border border-transparent bg-red-600 px-3 py-2 text-sm font-medium leading-4 text-white shadow-sm hover:bg-teal-700 focus:outline-none focus:ring-2 focus:ring-teal-500 focus:ring-offset-2 disabled:opacity-50"
                    >
                      Delete
                    </button>
                  </td>
                </tr>

                <!-- More people... -->
              </tbody>
            </table>
            <div class="flex flex-row w-full justify-around">
              <button
                *ngIf="currentPage > 1"
                type="button"
                (click)="changePage(-1)"
                class="inline-flex items-center rounded-md border border-transparent bg-teal-600 px-3 py-2 text-sm font-medium leading-4 text-white shadow-sm hover:bg-teal-700 focus:outline-none focus:ring-2 focus:ring-teal-500 focus:ring-offset-2 disabled:opacity-50"
              >
                GoBack
              </button>

              <p class="text-sm text-gray-700">
                Showing
                <span class="font-medium">{{ this.offset + 1 }}</span> to
                <span class="font-medium">{{ calculateRemaingItems() }}</span>
                of
                <span class="font-medium">{{ this.totalExercises }}</span>
              </p>
              <button
                *ngIf="calculateRemaingItems() < this.totalExercises"
                type="button"
                (click)="changePage(1)"
                class="inline-flex items-center rounded-md border border-transparent bg-teal-600 px-3 py-2 text-sm font-medium leading-4 text-white shadow-sm hover:bg-teal-700 focus:outline-none focus:ring-2 focus:ring-teal-500 focus:ring-offset-2 disabled:opacity-50"
              >
                NextPage
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  `,
  styles: [],
})
export class ExerciseLibraryComponent implements OnInit, OnDestroy {
  exerciseSubscription: Subscription | undefined;
  exercises: Exercise[] = [];
  muscleGroupOrderToggle = false;
  nameOrderToggle = false;
  newExerciseToggle = false;
  exerciseID: string | null = null;
  currentPage = 1;
  totalExercises: number = 0;
  displayExercises: Exercise[] = [];
  offset = 0;

  searchNameValue = '';

  constructor(private exerciseService: ExerciseService) {}

  ngOnInit(): void {
    this.loadExercises();
  }

  loadExercises(): void {
    this.exerciseSubscription = this.exerciseService
      .getAllExercisesForUser()
      .subscribe((data) => {
        this.exercises = data.sort((a, b) =>
          a.exerciseName!.localeCompare(b.exerciseName!),
        );

        this.displayExercises = this.exercises.slice(0, 20);

        this.totalExercises = this.exercises.length;
      });
  }

  loadExercisesPage() {
    if (this.exerciseSubscription) this.exerciseSubscription.unsubscribe();

    this.exerciseSubscription = this.exerciseService
      .getAllExercisesPerPageForUser(20, 0)
      .subscribe((data) => {
        this.exercises = data;
      });
  }

  calculateRemaingItems() {
    if (this.currentPage * 20 < this.totalExercises) {
      return this.offset + 20;
    } else {
      return this.offset + 20 + (this.totalExercises - this.currentPage * 20);
    }
  }

  changePage(pageNumber: number) {
    this.currentPage += pageNumber;
    const pageSize = 20;
    this.offset = (this.currentPage - 1) * pageSize;

    if (this.offset < 0) {
      this.currentPage = this.currentPage + 1;
      return null;
    }

    if (this.offset > this.totalExercises) {
      this.currentPage = this.currentPage - 1;
      return null;
    }

    this.displayExercises = this.exercises.slice(
      this.offset,
      this.offset + pageSize,
    );

    return true;
  }

  onKeyDownSearch(event: KeyboardEvent) {
    if (this.searchNameValue === '') {
      this.displayExercises = this.exercises.slice(0, 20);
    } else {
      this.displayExercises = this.exercises.filter((ex) =>
        ex.exerciseName
          ?.toLowerCase()
          .includes(this.searchNameValue.toLowerCase()),
      );
    }
  }

  onClearSearch() {
    if (!this.searchNameValue) {
      this.displayExercises = this.exercises.slice(0, 20);
    }
  }

  ngOnDestroy() {
    this.exerciseSubscription?.unsubscribe();
  }

  onTableHeaderClick(value: string) {
    if (this.exercises.length > 2) {
      switch (value) {
        case 'Muscle Group':
          this.muscleGroupOrderToggle = !this.muscleGroupOrderToggle;
          this.exercises.sort(
            this.sortWithDirection('muscleGroup', this.muscleGroupOrderToggle),
          );

          break;
        case 'Name':
          this.nameOrderToggle = !this.nameOrderToggle;
          this.exercises.sort(
            this.sortWithDirection('exerciseName', this.nameOrderToggle),
          );

          break;
      }
    }
  }

  getDuplicates() {
    let temp: Exercise[] = [];
    let deletes: Exercise[] = [];

    //console.log(this.exercises);

    this.exercises.forEach((element) => {
      // Check if there is a matching element in temp with different description
      let match = temp.find(
        (r) =>
          r.exerciseName === element.exerciseName &&
          r.exerciseDescription !== element.exerciseDescription,
      );

      if (match) {
        // Add the current element to temp
        temp.push(element);
      } else {
        // Check if there's a matching element in temp with the same name and different description
        let duplicate = temp.find(
          (r) =>
            r.exerciseName === element.exerciseName &&
            r.exerciseDescription === element.exerciseDescription,
        );

        if (duplicate) {
          let duplicateCopy: Exercise = { ...duplicate };
          // If a duplicate is found, replace it with the current element
          Object.assign(duplicateCopy, element);
          deletes.push(duplicateCopy);
        } else {
          // If no duplicate is found, add the current element to temp
          temp.push(element);
        }
      }
    });

    return deletes;
  }

  mergeDuplicates() {
    const deletes = this.getDuplicates();
    //console.log('Unique items:', temp);
    //console.log('Deleted duplicates:', deletes);
    /*
    console.log('deletes', deletes)
    deletes.forEach((deleteElement) => {
      if (deleteElement && deleteElement.id)
        this.exerciseService.delete(deleteElement.id);
    });
    */
    // console.log('deletes', deletes);
    if (deletes) {
      this.exerciseService.deleteBatch(deletes);
    }
  }

  checkForDuplicates() {
    const deletes = this.getDuplicates();
    if (deletes.length >= 1) {
      return true;
    }
    return false;
  }

  deleteDuplicates() {
    this.mergeDuplicates();
  }

  sortWithDirection(property: keyof Exercise, order: boolean) {
    let direction = 1;
    if (!order) direction = -1;

    return function (a: any, b: any) {
      let result =
        a[property].toLowerCase() < b[property].toLowerCase()
          ? -1
          : a[property].toLowerCase() > b[property].toLowerCase()
            ? 1
            : 0;
      return result * direction;
    };
  }

  onNewExercise() {
    this.exerciseID = null;
    this.newExerciseToggle = !this.newExerciseToggle;
  }

  onEditExercise(id: string) {
    this.exerciseID = id;
    this.newExerciseToggle = !this.newExerciseToggle;
  }

  onDeleteExercise(id: string) {
    this.exerciseService.delete(id);
  }

  onFormSaved(event: boolean) {
    if (event) {
      this.newExerciseToggle = !this.newExerciseToggle;
    }
  }

  oldExLib: Exercise[] = [];
  getAllOldEx() {
    this.exerciseService.getAllOldExercisesForUser().subscribe((data) => {
      if (data) {
        this.oldExLib = data;
        console.log(this.oldExLib);
      }
    });
  }

  SaveToNew() {
    if (this.oldExLib.length > 1) {
      console.log('saving old exercise');
      this.exerciseService.saveAllOldExercisesToNewUser(this.oldExLib);
    }
  }

  deleteOld() {
    if (this.oldExLib.length > 1) {
      this.oldExLib.forEach((ex) => {
        this.exerciseService.deleteOld(ex.id as string);
      });
    }
  }
}
